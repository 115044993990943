import React from 'react'
import styled, { css } from 'styled-components'
import { ZIndex, breakpoints, toCssPrefix, skeletonAnimationStyles, ProductCardType } from '@ecommerce/shared'

type Props = {
  type?: ProductCardType
}

const { cssPrefix, toPrefix } = toCssPrefix('ProductCardSkeleton__')

const Wrapper = styled.div<Props>`
  padding: 0 18px;
  z-index: ${ZIndex.low};

  .item {
    width: 100%;
    background: ${({ theme }) => theme.colors.grey};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius};
    ${skeletonAnimationStyles}
  }

  .${cssPrefix} {
    &inner {
      width: 190px;
      height: 335px;
      padding: 12px;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
    }

    &image {
      height: 160px;
      margin-bottom: 12px;
    }

    &info {
      height: 20px;
      margin-bottom: 8px;
    }

    &price {
      height: 25px;
      margin: 20px 0 12px 0;
    }

    &button {
      height: 35px;
      width: 90%;
      margin: auto;
    }
  }

  ${({ type }) => {
    if (type !== 'small') {
      return css`
        @media screen and (${breakpoints.desktop.min}) {
          padding: 0 28px 0 0;

          .${cssPrefix} {
            &inner {
              width: 240px;
              height: 100%;
              padding: 15px;
            }

            &image {
              height: 175px;
            }

            &info {
              height: 30px;
              margin-bottom: 10px;
            }

            &price {
              height: 40px;
              margin: 25px 0 15px 0;
            }

            &button {
              height: 40px;
              width: 160px;
              margin: 22px auto 0;
            }
          }
        }
      `
    }
  }}
`

const ProductCardSkeleton = () => (
  <Wrapper className={`${cssPrefix} MomentsFilteredList__product-card`}>
    <div className={toPrefix('inner')}>
      <div className={toPrefix('image item')} />
      <div className={toPrefix('info item')} />
      <div className={toPrefix('info item')} />
      <div className={toPrefix('price item')} />
      <div className={toPrefix('button item')} />
    </div>
  </Wrapper>
)

export default ProductCardSkeleton
