import React from 'react'
import styled from 'styled-components'
import SwiperCore, { Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { breakpoints } from '@ecommerce/shared'
import SkeletonItem from '../Skeletons/SkeletonItem'
import { Filter } from '../../graphql/contentfulTypes'
import FilterComponent from './Filter'

SwiperCore.use([Mousewheel])

type Props = React.HTMLAttributes<HTMLDivElement> & {
  filters: Filter[]
  activeFilter: Filter['filter']
  onFilterClick: (selectedFilter: string) => void
  isLoading: boolean
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  flex-wrap: nowrap;

  .filters-swiper {
    width: 100%;
    margin: 0 auto 0 0;
  }

  .filters-slide {
    min-width: 140px;
    max-width: 220px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    .filters-swiper {
      width: 85%;
    }
  }
`

const FiltersList = ({ filters, activeFilter, onFilterClick, isLoading, className = '' }: Props) => {
  const newActiveFilter = activeFilter === '' ? 'ALL' : activeFilter
  const preventAndStop = (e: Event | React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Wrapper className={className}>
      <Swiper
        onMouseDown={preventAndStop}
        onMouseUp={preventAndStop}
        onClick={(_, e) => preventAndStop(e)}
        className="filters-swiper"
        slidesPerView="auto"
        mousewheel
      >
        {isLoading ? (
          <>
            {Array(8)
              .fill(0)
              .map((_, i) => (
                <SwiperSlide className="filters-slide" key={i}>
                  <SkeletonItem data-testid="filters-list-skeleton" width="200px" margin="0 8px" animate />
                </SwiperSlide>
              ))}
          </>
        ) : (
          <>
            {filters.map((filter) => (
              <SwiperSlide className="filters-slide" key={filter.filter}>
                <FilterComponent
                  data-testid="filters-list-item"
                  key={filter.filter}
                  onClick={() => onFilterClick(filter.filter)}
                  isActive={newActiveFilter === filter.filter}
                  item={filter}
                />
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </Wrapper>
  )
}

export default FiltersList
