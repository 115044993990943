import React, { useEffect, useState } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { SwiperSlide, Swiper } from 'swiper/react'
import SwiperCore from 'swiper'
import {
  ProductCard,
  toCssPrefix,
  breakpoints,
  slugify,
  getProducts,
  Product,
  showToast,
  useLocation,
  getStoredMarket,
  getStoredDistributionCenter,
  ProductCardType,
  getCurrencyCode,
  AlgoliaProduct,
  useResolution,
  toFormattedProduct,
} from '@ecommerce/shared'
import ProductCardSkeleton from '../../Skeletons/ProductCardSkeleton'
import { QueryResult } from '../../../graphql/moments/productListQuery'
import { FilterProduct, Filter, BlockTitle } from '../../../graphql/contentfulTypes'
import { getNavigatableProductsBySkus } from '../../../utils/algolia'
import { useCartStockErrorHandler } from '../../../utils/errors'
import Filters from '../../FiltersList'
import useQuery from '../../../hooks/useQuery'
import { Icon } from '../../Icon/Icon'
import SectionTitle from '../SectionTitle'
import filteredListQuery from '../../../graphql/filteredListQuery'
import slugFallback from '../../../utils/slugFallback'
import secrets from '../../../config/secrets'
import { sendViewItemListEvent } from '../../../utils/events'
import { generateProductUrl } from '../../../config/siteBuild/utils'

const { cssPrefix, toPrefix } = toCssPrefix('MomentsFilteredList__')

export interface OnCardClickArgs {
  product: Product
  listName: string
  themeId?: string
}

type Props = {
  className?: string
  listId: string
  themeId?: string
  onCardClick?: (args: OnCardClickArgs) => void
  background?: string
  isHome?: boolean
  typeCard?: ProductCardType
  items?: AlgoliaProduct[]
}

type StateData = {
  title?: string
  attributeToFilter?: Pick<Product, 'brandName' | 'categoryName'>
  filters: Filter[]
  filterItems?: FilterProduct[]
  actionSlug?: string
  optionalFilter?: string
} & Omit<BlockTitle, 'title'>

const Wrapper = styled.div<{ background?: string; isHome?: boolean }>`
  padding-top: 10px;
  margin-bottom: 80px;
  width: 100%;
  position: relative;

  .swiper {
    &-button {
      &-next,
      &-prev {
        display: none;
        opacity: 0.5;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: 200ms;

        &::after {
          font-weight: bolder;
          font-size: 18px;
        }

        &:hover {
          opacity: 0.5;
          background-color: ${({ theme }) => theme.colors.red};
        }
      }
    }
  }

  ${({ background }) =>
    background
      ? css`
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            min-height: 345px;
            top: 285px;
            background-image: url(${background});
            background-repeat: no-repeat;
            background-size: cover;
          }
        `
      : css`
          background: linear-gradient(
            0,
            ${({ theme }) => theme.colors.background.bodyAlternative} 250px,
            transparent 0
          );
        `}
  ${({ background, isHome }) =>
    !background && isHome
      ? css`
          background: linear-gradient(
            0,
            ${({ theme }) => theme.colors.background.bodyAlternative} 345px,
            transparent 0
          );
          padding: 10px 0 0 0;
        `
      : css`
          background: linear-gradient(
            0,
            ${({ theme }) => theme.colors.background.bodyAlternative} 250px,
            transparent 0
          );
        `}

  

  .${cssPrefix} {
    &title {
      padding-left: 20px;
      max-width: 65%;
      margin: 20px 0 16px 0;
    }

    &filters {
      margin-bottom: 24px;
      padding: 0 20px 0;
      position: relative;
    }

    &slider-wrapper {
      overflow: hidden;
      width: 100%;
      font-size: 24px;
    }

    &slider {
      padding: 8px 20px;

      &-slide {
        width: 224px;
      }
    }

    &show-more {
      color: ${({ theme }) => theme.colors.bodyText};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      position: absolute;
      right: 16px;
      top: -45px;
      text-decoration: none;
    }

    &product-card {
      box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
      border: none;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .swiper {
      &-button {
        &-next,
        &-prev {
          display: flex;
        }
        &-prev {
          left: 20px;
        }
      }
    }
    .${cssPrefix} {
      &container {
        padding: 20px 60px;
      }
      &slider {
        &-slide {
          width: 277px;
        }
      }

      &filters {
        padding: 0 30px 0;
      }

      &show-more {
        right: 0;
        top: 8px;
      }
    }
  }
`

const All_ITEMS_FILTER = 'ALL'

const FilteredProductList = ({ listId, className, items, isHome, onCardClick, themeId, typeCard, ...props }: Props) => {
  const [activeFilter, setActiveFilter] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [stateData, setStateData] = useState<StateData>({ filterItems: [], filters: [] })
  const [searchSkus, setSearchSkus] = useState<StateData['filterItems']>([])
  const [activeProducts, setActiveProducts] = useState<Product[]>([])
  const [productListName, setProductListName] = useState('')
  const { COUNTRY } = secrets

  const { isBolivia } = useLocation()
  const { isMobile } = useResolution()

  const currentMarket = getStoredMarket()
  const currentDistributionCenter = getStoredDistributionCenter()

  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  const resetSliderPosition = () => swiper?.slideTo(0)

  const { data, loading, error } = useQuery<QueryResult>(filteredListQuery, { variables: { listId } })

  const stockErrorHandler = useCartStockErrorHandler()

  const showToastNotification = () =>
    showToast({ content: 'Ha habido un error, por favor intenta de nuevo mas tarde.', type: 'error' })

  const getSkusToSearch = (filter: string) =>
    filter === All_ITEMS_FILTER
      ? stateData.filterItems
      : stateData?.filterItems?.filter((item) => item.categoryName === filter)

  const handleItemsChange = async () => {
    try {
      const products = searchSkus

      if (products && products.length !== 0 && currentDistributionCenter) {
        setIsLoading(true)

        const skus = products.map((product) => product.sku)
        const algoliaProducts = await getNavigatableProductsBySkus(
          slugify(currentDistributionCenter?.commerceLayer.stockLocation.name),
          skus,
          { hitsPerPage: 1000 },
          currentMarket?.slug,
          productListName,
        )
        const sortedProducts = skus
          .map((key) => algoliaProducts.filter((product) => `${product.skuCode}` === key)[0])
          .filter((item) => item)

        setActiveProducts(sortedProducts)
        sendViewItemListEvent({
          products: sortedProducts,
          currency: getCurrencyCode(COUNTRY),
          list: stateData.title,
        })
        setIsLoading(false)
        resetSliderPosition()
      }
    } catch (e) {
      if (activeFilter !== All_ITEMS_FILTER) return setActiveFilter(All_ITEMS_FILTER)

      showToastNotification()
    }
  }

  const buildAttributeFilters = () => {
    const attributeName = stateData.attributeToFilter
    return stateData?.filters
      ?.filter((filter) => filter.filter !== All_ITEMS_FILTER)
      .map((filter) => `${attributeName}:${filter.filter}`)
  }

  const handleQueryByAttribute = async () => {
    if (currentDistributionCenter && activeFilter !== '') {
      setIsLoading(true)

      const attributeName = stateData.attributeToFilter
      const filters = activeFilter === All_ITEMS_FILTER ? buildAttributeFilters() : [`${attributeName}:${activeFilter}`]
      const products = await getProducts(
        `${
          isBolivia()
            ? 'hasStock:true AND'
            : `${isHome ? 'hasStock: true AND' : ''} ${
                stateData?.optionalFilter ? `${stateData?.optionalFilter} AND ` : ''
              }`
        } slugLocation:${slugify(currentDistributionCenter?.commerceLayer.stockLocation.name)} AND NOT isDummy:true`,
        {
          hitsPerPage: 15,
          facetFilters: [filters],
        },
      )

      setActiveProducts(products)
      setIsLoading(false)
      resetSliderPosition()
      sendViewItemListEvent({
        products,
        currency: getCurrencyCode(COUNTRY),
        list: stateData.title,
      })
    }
  }

  useEffect(() => {
    const skus = getSkusToSearch(activeFilter)

    if ((!skus || skus?.length === 0) && !loading) {
      handleQueryByAttribute()
    } else {
      setSearchSkus(skus)
    }
  }, [activeFilter])

  useEffect(() => {
    if (!loading) {
      const orList = data?.orList

      if (error || !orList || !orList?.itemsCollection) {
        showToastNotification()
        return
      }

      const {
        attributeToFilter,
        filtersCollection: { items: filters },
        itemsCollection,
        optionalFilter,
      } = orList.itemsCollection.items[0]
      const products = itemsCollection?.items ?? []

      setStateData({
        filters,
        filterItems: products,
        attributeToFilter,
        title: orList.title,
        titleColor: orList.titleColor,
        centerTitle: orList.centerTitle,
        titleBorderColor: orList.titleBorderColor,
        titleBorderColorShadowNeon: orList.titleBorderColorShadowNeon,
        allowTitleLowerCase: orList.allowTitleLowerCase,
        actionSlug: typeof orList?.actionSlug === 'string' ? `${slugFallback(orList.actionSlug)}` : undefined,
        optionalFilter,
      })
      setSearchSkus(products)

      if (orList.name) setProductListName(orList.name)
    }
  }, [data, loading])

  useEffect(() => {
    if (items) {
      const products = items.map((item) => toFormattedProduct(item))
      setActiveProducts(products)
      setIsLoading(false)
    }
  }, [items])

  useEffect(() => {
    handleItemsChange()
  }, [searchSkus])

  useEffect(() => {
    if (stateData.attributeToFilter && !stateData?.filterItems?.length && !loading) {
      handleQueryByAttribute()
    }
  }, [stateData, loading])

  return (
    <Wrapper className={`${cssPrefix} ${className}`} {...props} isHome={isHome}>
      <div className={toPrefix('container')}>
        {stateData?.title && (
          <SectionTitle
            text={stateData?.title}
            color={stateData?.titleColor}
            className={toPrefix('title')}
            {...stateData}
          />
        )}
        <div className={toPrefix('filters')}>
          {stateData?.actionSlug && (
            <Link state={{ themeId }} to={stateData.actionSlug} className={toPrefix('show-more')}>
              Ver todo
              <Icon className={toPrefix('show-more-button')} iconId="chevron_right" size="26" />
            </Link>
          )}
          <Filters
            isLoading={stateData.filters.length === 0}
            onFilterClick={setActiveFilter}
            activeFilter={activeFilter}
            filters={stateData.filters}
          />
        </div>
        <div className={toPrefix('slider-wrapper')}>
          <Swiper
            navigation
            slidesPerView="auto"
            spaceBetween={isMobile ? 20 : 30}
            className={`${toPrefix('slider')} swiper`}
          >
            {isLoading ? (
              <>
                {Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <SwiperSlide className={toPrefix('slider-slide')} key={i}>
                      <ProductCardSkeleton key={i} />
                    </SwiperSlide>
                  ))}
              </>
            ) : (
              <>
                {activeProducts.map((product) => (
                  <SwiperSlide className={toPrefix('slider-slide')} key={product.skuCode}>
                    <ProductCard
                      useDragHandler={false}
                      key={product.skuCode}
                      className={toPrefix('product-card')}
                      type={typeCard}
                      product={product}
                      onNavigate={() => {
                        if (onCardClick) return onCardClick({ product, listName: productListName, themeId })
                        navigate(`/products/${generateProductUrl(product.title, product.skuCode)}`, {
                          state: { themeId },
                        })
                      }}
                      stockErrorHandler={stockErrorHandler}
                      isHome={isHome}
                    />
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </div>
      </div>
    </Wrapper>
  )
}

export default FilteredProductList
