import React from 'react'
import styled from 'styled-components'
import { toCssPrefix } from '@ecommerce/shared'
import { Filter as FilterTypes } from '../../graphql/contentfulTypes'

const { cssPrefix, toPrefix } = toCssPrefix('FilterItem__')

type Props = React.HTMLAttributes<HTMLDivElement> & {
  item: FilterTypes
  isActive: boolean
}

const Wrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  max-width: 220px;
  height: 40px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.iconsColor : theme.colors.bodyText)};
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.background.layout : 'transparent')};
  font-size: 17px;
  font-weight: bold;
  border: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.background.layout : theme.colors.bodyText)};
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  margin: 0 22px;
  padding: 0 16px;

  &:first-child {
    margin-left: 0;
  }

  .${cssPrefix} {
    &label {
      margin: 0;
      white-space: nowrap;
      user-select: none;
    }
  }
`

const Filter = ({ item, isActive = false, className = '', ...restProps }: Props) => (
  <Wrapper {...restProps} isActive={isActive} className={`${cssPrefix} ${className}`}>
    <p className={toPrefix('label')}>{item.label}</p>
  </Wrapper>
)

export default Filter
