import { OrListFragment, OrFilteredListFragment } from './fragments'
import { BlockType, ClientSideFilteredList } from './contentfulTypes'

export interface QueryFilteredListResult {
  orList: {
    blockType: BlockType.FILTERED_LIST
    itemsCollection: {
      items: ClientSideFilteredList[]
    }
  }
}

export default `
  query FilteredListQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment 
      itemsCollection {
        items {
          ...OrFilteredListFragment
        }
      }
    }
  }
  ${OrListFragment}
  ${OrFilteredListFragment}
`
